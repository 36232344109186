import tito from "../../assets/team_images/tito.jpg"
import ariana from "../../assets/team_images/ariana.jpg"
import nandan from "../../assets/team_images/nandan.jpg"
import finnegan from "../../assets/team_images/finnegan.png"

const teamData = [

  {
    id: 1,
    name: "Tito",
    jobTitle: "Motion Graphics + Cinematographer",
    image: tito,
    //description: "The Diego Hargreeves (#2) of Motion Graphics and Production"
  },

  {
    id: 2,
    name: "Nandan",
    jobTitle: "Visual Effects + Technology",
    image: nandan,
    //description: "The Number 5 of VFX and Pipeline Development"
  },

  {
    id: 3,
    name: "Ariana",
    jobTitle: "Visual Effects + Producer",
    image: ariana,
    //description: "The Klaus Hargreeves (#4) of VFX and VP"
  },
  {
    id: 4,
    name: "Finnegan",
    jobTitle: "Meow-tion Graphics Artist",
    image: finnegan,
    //description: "The Klaus Hargreeves (#4) of VFX and VP"
  },




];

export default teamData